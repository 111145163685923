import request from "@/utils/request";
const test = location.origin.indexOf("test-asa.qiumo.net.cn") >= 0;
const ipBaseURL = test ? "http://10.12.21.173:8086" : "https://pojie.ai-me.cn";

// 发送短信
export const sendSMS = (data, params) =>
  request({
    url: "/op-service/v1/zunxiang20240613/send-sms/",
    method: "post",
    ipBaseURL: ipBaseURL,
    data,
    params,
  });

// 订购业务
export const buy = (data, params) =>
  request({
    url: "/op-service/v1/zunxiang20240613/buy/",
    method: "post",
    ipBaseURL: ipBaseURL,
    data,
    params,
  });

<template>
  <div class="container">
    <img class="top" src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/yunnan/yunnan_dx01.png" />
    <div class="scroll_tips">
      <ul class="scroll_box">
        <li class="scroll_item">
          176****6142抢到了
        </li>
        <li class="scroll_item">
          131****7394抢到了
        </li>
        <li class="scroll_item">
          155****4549抢到了
        </li>
        <li class="scroll_item">
          130****5436抢到了
        </li>
        <li class="scroll_item">
          156****0054抢到了
        </li>
        <li class="scroll_item">
          171****5245抢到了
        </li>
        <li class="scroll_item">
          175****6540抢到了
        </li>
        <li class="scroll_item">
          185****0974抢到了
        </li>
      </ul>
    </div>
    <div class="form" style="padding: 40px 0 20px 0;">
      <img class="step1" v-if="num == 1" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif" />
      <van-field v-model="form.phone_number" type="tel" maxlength="11" placeholder="请输入您的手机号码" />
      <img class="step2" v-if="num == 2" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_04.gif" />
      <img class="step3" v-if="num == 3" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_05.gif" />
      <van-field v-model="form.phone_code" type="number" center maxlength="6" autocomplete="one-time-code"
        placeholder="请输入短信验证码" class="input-code">
        <template slot="right-icon">
          <div class="code" v-show="sendAuthCode" @click="getAuthCode">
            | 获取验证码
          </div>
          <div class="code" v-show="!sendAuthCode">
            <span> | {{ auth_time }}秒</span>重新获取
          </div>
        </template>
      </van-field>

      <div class="checked">
        <van-checkbox checked-color="#ff840d" v-model="checked" icon-size="12px">
          我已阅读并同意 <span class="book" @click.stop="dialogbuy">《购买须知》</span> <span class="book"
            @click.stop="dialogInfo">《个人信息授权保护协议》</span>
        </van-checkbox>
      </div>
      <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/submit-lijidinggou.gif" class="submit"
        @click="onSubmit" />

    </div>
    <div class="info">
      <img style="width: 100%;"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/yunnan/yunnan_dx02.png" />
      <img style="width: 100%;"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/yunnan/yunnan_dx03.png" />
      <img style="width: 100%;"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/yunnan/yunnan_rule.png" />
    </div>
    <!-- 二确 -->
    <BaseDialogConfirm :visibility.sync="visibility" :closeBtnImg="closeBtnImg" :submitBtnImg="submitBtnImg"
      :background="background" />
  </div>
</template>

<script>
import BaseDialogConfirm from "@/components/BaseDialog/confirm.vue";
import { sendSMS, buy } from "@/api/qiuMoFusion/duzan_01.js";
import { generateSign } from "@/utils/common";
import tailJumpMixin from "@/utils/tailJump.js";
export default {
  mixins: [tailJumpMixin],
  components: {
    BaseDialogConfirm
  },
  data() {
    return {
      yzm_loading: false,
      form: {
        phone_number: "",
        phone_code: "",
      },
      sendAuthCode: true,
      checked: false,
      auth_time: 0,
      state: false,
      num: 1,
      visibility: false,
      userAgent: "",
      closeBtnImg: "https://cdn.qiumo.net.cn/allsmarketmaterial/vip_dx/dialog-close.png",
      submitBtnImg: "https://cdn.qiumo.net.cn/allsmarketmaterial/vip_dx/dialog-submit.png",
      background: "https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/yunnan/yunnan_bg01.png",
    };
  },
  computed: {
    link() {
      return `${location.origin}/pages/qiuMoFusion/ALL/dx_fusion`;
    },
  },
  mounted() {
    const that = this;

    window.addEventListener("scroll", that.handlerscroll);
    window.onpopstate = function (event) {
      // 禁止返回上一页
      window.location.replace(
        that.tailJumpUrl(that.link, `QIUMORONGHE`, that.form.phone_number, "退出")
      );
    };
  },
  created() {
    this.userAgent = navigator.userAgent;
    const { phone = "" } = this.$route.query;
    this.form = {
      ...this.form,
      callback: location.search,
      phone_number: phone,
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  beforeDestroy() {
    // 销毁时移除事件监听，防止影响其他页面
    window.onpopstate = null;
  },
  watch: {
    form: {
      handler(value) {
        const { phone_number = "", phone_code } = value;
        if (phone_number.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && phone_code.length == 0) {
          this.num = 2;
        } else if (phone_code.length < 6) {
          this.num = 3;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {

    dialogInfo() {
      this.$dialog.alert({
        messageAlign: "left",
        title: "个人信息授权与保护声明",
        message: `尊敬的客户：
您好！
根据《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在我公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理移动电话（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名或名称、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效身份证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。
为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息：
（1）事先获得客户的明确授权；
（2）根据有关的法律法规要求；
（3）按照相关司法机关和/或政府主管部门的要求；
（4）为维护社会公众的利益所必需且适当；
（5）为维护我公司或客户的合法权益所必需且适当。`,
      });
    },
    dialogbuy() {
      this.$dialog.alert({
        messageAlign: "left",
        title: "购买须知",
        message: `一、资费说明
1.套餐费：19.9元/月，首月一次性收取费用，每月月初一次性收取费用。包含每月108选1权益和10GB国内通用流量，其中流量当月全额提供，有效期12个月，到期后继续按此标准执行至您或我公司提出终止当月月底止。
2.权益内容：每月爱奇艺、优酷、腾讯视频、哔哩哔哩等权益108选1，其中权益内容将不定期调整，具体以领取页面实际显示为准，领取结果请以短信通知为准。
3.权益领取路径：翼支付APP—权益专区或云南电信微信公众号—权益中心。
二、业务规则
1.生效规则：订购立即生效。
2.重复订购规则：同一号码不可重复订购。
3.到期处理规则：12个月到期，到期后继续按此标准执行至您或我公司提出终止当月月底止。
4.退订规则：退订次月生效。若用户当月退订，自退订之日起当月内仍可领取权益。如退订用户可拨打100000号、或下载中国电信网上营业厅APP自行退订。
5.权益领取规则：仅限办理号码领取，当月（自然月）有效，当月未按时领取或欠费等原因未领取视作自动放弃，后续不支持补领。权益内容可能有所调整，具体以领取页面实际显示为准，权益有效期以领取页面说明为准。若办理号码已订购相关权益，领取权益后，有效期将在原有效期上自动叠加。
6.权益发放规则：权益发放会有延迟，领取后请等待几分钟后再查询。
7.流量使用规则：流量月底自动清零，主副卡不可共享流量，副卡可单独订购。
8.其他说明：权益使用过程中如有任何疑问，可联系在线客服或拨打4001189599客服热线咨询处理`,
      });
    },
    async getAuthCode() {
      if (this.yzm_loading) return;
      let filterPhone = /^1[3456789]\d{9}$/;
      const {
        phone_number = "",
      } = this.form;
      if (!filterPhone.test(phone_number)) {
        this.$dialog.alert({ message: "手机号错误" });
        return;
      }

      this.yzm_loading = true;
      try {
        const res = await sendSMS(
          {
            product_id: "72601001",
            phone_number,
            default_app_name: "无",
            default_package_name: "无",
            app_name: "无",
            auth: generateSign("QIUMO", "YUVFIUC(*OVO()"),
          }
        );

        if (res.errorCode === "000") {
          this.form = {
            ...this.form,
            response_order_id: res.response_order_id,
          };
          // 设置倒计时秒
          this.sendAuthCode = false;
          this.auth_time = 120;
          let auth_timetimer = setInterval(() => {
            this.auth_time--;
            if (this.auth_time <= 0) {
              this.sendAuthCode = true;
              clearInterval(auth_timetimer);
            }
          }, 1000);
        } else {
          this.$dialog.alert({ message: res.errorMsg });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(
                this.link,
                `QIUMORONGHE`,
                this.form.phone_number,
                "验证码下发失败"
              )
            );
          }, 3000);
        }
      } finally {
        this.yzm_loading = false;
      }
    },
    onSubmit() {
      let filtercode = /^\d{6}$/;
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone_number) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone_number)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.phone_code) {
        this.$dialog.alert({ message: "请输入验证码" });
      } else if (!filtercode.test(this.form.phone_code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      }
      else if (!this.checked) {
        this.$dialog.alert({ message: "请勾选并同意相关协议" });
      }
      else {
        this.visibility = true;
      }
    },
    async handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const res = await buy(
        { ...this.form, auth: generateSign("QIUMO", "YUVFIUC(*OVO()") },
      );
      this.visibility = false;
      this.$toast({
        duration: 8000,
        message: res.errorMsg,
      });
      if (res.errorCode === "000") {
        setTimeout(() => {
          window.location.replace(
            this.tailJumpUrl(
              this.link,
              `QIUMORONGHE`,
              this.form.phone_number,
              "成功"
            )
          );
        }, 3000);
      } else {
        setTimeout(() => {
          window.location.replace(
            this.tailJumpUrl(
              this.link,
              `QIUMORONGHE`,
              this.form.phone_number,
              "失败"
            )
          );
        }, 3000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  background-color: #e33b2a;

  .top {
    width: 100%;
  }

  .scroll_tips {
    position: absolute;
    top: 20%;
    width: 100%;
    margin: 0 auto 0;
    overflow: hidden;

    .scroll_box {
      height: 56px;
      animation: scroll 15s linear infinite;
      display: flex;

      .scroll_item {
        background-color: rgba(0, 0, 0, 0.44);
        border-radius: 40px;
        white-space: nowrap;
        width: 329px;
        text-align: center;
        font-weight: 500;
        flex-shrink: 0;
        line-height: 56px;
        font-size: 30px;
        color: white;
        margin-left: 62px;
      }

      @keyframes scroll {
        0% {
          transform: translateX(0%);
        }

        100% {
          transform: translateX(-317.2%);
        }
      }
    }
  }

  .form {
    position: relative;

    .step1,
    .step2,
    .step3 {
      width: 326px;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }

    .step1 {
      transform: translate(80px, -80px);
    }

    .step2 {
      width: 140px;
      right: 60px;
      top: 150px;
    }

    .step3 {
      width: 450px;
      transform: translate(84px, -20px);
    }

    ::v-deep .van-cell {
      width: 680px;
      height: 106px;
      background-color: #ffffff;
      margin: 0 auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 50px;
      border-radius: 53px;
      // border: solid 3px #b2b2b2;

      &.input-code {
        margin-top: 45px;

        .code {
          width: 252px;
          height: 106px;
          text-align: center;
          line-height: 106px;
          color: #ff840d;
          font-size: 34px;
          font-weight: normal;
          // background-color: #ff840d;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        color: #4d2f1c;
        font-weight: normal;
        font-size: 36px;

        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: 40;
          color: #969696;
        }
      }
    }

    .checked {
      font-size: 24px;
      font-weight: normal;
      color: #ffffff;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 20px;

      ::v-deep .van-checkbox__label {
        color: #ffffff;
      }

      .book {
        color: yellow;
      }
    }

    .submit {
      width: 654px;
      display: block;
      margin: 50px auto 0;
      animation: identifier 1s infinite;

      @keyframes identifier {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(0.9);
        }

        100% {
          transform: scale(1);
        }
      }
    }
  }

  .info {

    // margin-top: 50px;
    .title {
      width: 251px;
      display: block;
      padding: 81px 0 24px 0;
      margin: 0 auto;
    }

    .box {
      padding: 0 28px 100px;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
}
</style>